@import "bootstrap-scss";

@include media-breakpoint-up(xs) {
  .navbar {
    &.navbar-expand-xs {
      .navbar-collapse {
        max-height: none;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .navbar {
    &.navbar-expand-md {
      .navbar-collapse {
        max-height: none;
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .navbar {
    &.navbar-expand-sm {
      .navbar-collapse {
        max-height: none;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .navbar {
    &.navbar-expand-lg {
      .navbar-collapse {
        max-height: none;
      }
    }
  }
}

@include media-breakpoint-up(xl) {
  .navbar {
    &.navbar-expand-xl {
      .navbar-collapse {
        max-height: none;
      }
    }
  }
}
