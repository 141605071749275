@import "bootstrap-scss";

.collapse {
  max-height: 0;
  transition: max-height 0.15s ease-out;
  overflow: hidden;

  &.show {
    max-height: 1000px;
    transition: max-height 0.35s ease-in;
  }

  &:not(.show) {
    display: block;
  }
}
